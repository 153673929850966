<template>
  <div>
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
        style="padding-top: 0px;margin-top: 0px;"
          label="เลือกวันที่"
          readonly
          v-model="dateFormatted"
          persistent-hint
          prepend-icon="fa fa-calendar-week"
          @blur="date = parseDate(dateFormatted)"
          v-on="on"
          :disabled="disabled"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: ["value", "disabled"],
  data: vm => ({
    date: vm.datestring(),
    dateFormatted: vm.formatDate(vm.datestring()),
    menu1: false
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("input", this.date);
    });
  },
  watch: {
    date(v) {
      this.dateFormatted = this.formatDate(v);
      this.$emit("input", v);
    }
  },

  methods: {
    datestring(){
       var date = new Date();
      console.log(date)
       var m = date.getMonth()+1;
       var d = date.getDate();
       var y = date.getFullYear();
      var da = `${y}-${m}-${d}`;
      console.log(da)
      return da.replace(',','');
    },
    formatDate(date) {
      if (!date) return null;
      const [year,month,day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  }
};
</script>
