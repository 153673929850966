import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vDate from "@/components/datetime.vue";
import vSnackbar from "@/components/snackbar.vue";
import swal from "sweetalert";
Vue.component("v-date", vDate);
Vue.component("v-snackbar", vSnackbar);

const cSnackbar = (Vue.prototype.noti = new Vue(vSnackbar).$mount());
Vue.prototype.swal = swal;
document.body.appendChild(cSnackbar.$el);

import PrintJs from "print-js";
Vue.prototype.PrintJs = PrintJs;

import PassAccept from "@/plugins/passsetting";
Vue.prototype.PassAccept = PassAccept;

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
