<template>
  <div class="text-center">
    <v-snackbar absolute top dense v-model="snackbar" :timeout="timeout" :color="color">{{ text }}</v-snackbar>
  </div>
</template>
<script>
export default {
  data: () => ({
    snackbar: false,
    text: "My timeout is set to 2000.",
    timeout: 2000,
    color: "#8BC34A"
  }),
  methods: {
    Show(msg, color) {
      this.snackbar = true;
      this.text = msg;
      if (color == "red") color = "#F44336";
      this.color = color == null ? "#8BC34A" : color;
    }
  }
};
</script>
