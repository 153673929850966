import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    component: () =>
        import("@/views/main.vue"),
    children: [
        {
            path: "Home",
            name: "Home",
            component: () =>
                import("@/views/main/home.vue")
        },
        {
            path: "Employee",
            name: "Employee",
            component: () =>
                import("@/views/employee/employee.vue")
        },
        {
            path: "Employee/Add",
            name: "EmployeeAdd",
            component: () =>
                import("@/views/employee/add.vue")
        },
        {
            path: "Member",
            name: "Member",
            component: () =>
                import("@/views/member/member.vue")
        },
        {
            path: "Member/add",
            name: "MemberAdd",
            component: () =>
                import("@/views/member/add.vue")
        },
        {
            path: "Rubber",
            name: "Rubber",
            component: () =>
                import("@/views/rubber/rubber.vue")
        },
        {
            path: "Rubber/add",
            name: "RubberAdd",
            component: () =>
                import("@/views/rubber/add.vue")
        },
        {
            path: "Rubber/Detail",
            name: "RubberDetail",
            component: () =>
                import("@/views/rubber/rubberdetail.vue")
        },
        {
            path: "Billsale",
            name: "Billsale",
            component: () =>
                import("@/views/billsale/billsale.vue")
        },
        {
            path: "Billsale/History",
            name: "BillsaleHistory",
            component: () =>
                import("@/views/billsale/historybill.vue")
        },
        {
            path: "Finance/Wage",
            name: "FinanceWage",
            component: () =>
                import("@/views/finance/wage.vue")
        },
        {
            path: "Finance/Wage/Add",
            name: "FinanceWageAdd",
            component: () =>
                import("@/views/finance/wageadd.vue")
        },
        {
            path: "/Finance/Wage/History",
            name: "FinanceWageHistory",
            component: () =>
                import("@/views/finance/historyreport.vue")
        },
        {
            path: "Finance/Widen",
            name: "FinanceWiden",
            component: () =>
                import("@/views/finance/widen.vue")
        },
        {
            path: "Finance/Widen/Add",
            name: "FinanceWidenAdd",
            component: () =>
                import("@/views/finance/widanadd.vue")
        },
        {
            path: "/Finance/Widen/History",
            name: "FinanceWidenHistory",
            component: () =>
                import("@/views/finance/historyreport.vue")
        },
        {
            path: "Finance/Lend",
            name: "FinanceLend",
            component: () =>
                import("@/views/finance/lend.vue")
        },
        {
            path: "Finance/Lend/Add",
            name: "FinanceLendAdd",
            component: () =>
                import("@/views/finance/lendadd.vue")
        },
        {
            path: "/Finance/Lend/History",
            name: "FinanceLendHistory",
            component: () =>
                import("@/views/finance/historyreport.vue")
        },
        {
            path: "Finance/WidenEMP/AddEmp",
            name: "FinanceWidenAddEmp",
            component: () =>
                import("@/views/finance/widanempadd.vue")
        },
        {
            path: "Finance/WidenEMP",
            name: "FinanceWidenEmp",
            component: () =>
                import("@/views/finance/widenemp.vue")
        },
        {
            path: "/Finance/WidenEMP/History",
            name: "FinancWidenEMPHistory",
            component: () =>
                import("@/views/finance/historyreport.vue")
        },
        {
            path: "/Finance/Receive",
            name: "FinanceReceive",
            component: () =>
                import("@/views/finance/receive.vue")
        },
        {
            path: "/Finance/Receive/Add",
            name: "FinanceReceivetAdd",
            component: () =>
                import("@/views/finance/receiveadd.vue")
        },
        {
            path: "/Finance/Receive/History",
            name: "FinanceReceiveHistory",
            component: () =>
                import("@/views/finance/historyreport.vue")
        },
        {
            path: "/Finance/Money",
            name: "FinanceMoney",
            component: () =>
                import("@/views/finance/Money.vue")
        },
        {
            path: "/report/billsale",
            name: "reportbillsale",
            component: () =>
                import("@/views/report/reportbillSale.vue")
        },
        {
            path: "/report/member",
            name: "reportmember",
            component: () =>
                import("@/views/report/reportmemBer.vue")
        },
        {
            path: "/report/member/member",
            name: "reportmembermember",
            component: () =>
                import("@/views/report/reportSubwiden.vue")
        },
        {
            path: "/report/widen",
            name: "reportwiden",
            component: () =>
                import("@/views/report/reportwiden.vue")
        }, {
            path: "/report/money",
            name: "reportmoney",
            component: () =>
                import("@/views/report/reportmoney.vue")
        }, {
            path: "/report/recieve",
            name: "reportrecieve",
            component: () =>
                import("@/views/report/reportrecieve.vue")
        },
        {
            path: "/report/lend",
            name: "reportlend",
            component: () =>
                import("@/views/report/reportlend.vue")
        },
        {
            path: "/report/abstract",
            name: "reportabstract",
            component: () =>
                import("@/views/report/reportabstract.vue")
        },
        {
            path: "Org",
            name: "Org",
            component: () => import("@/views/organization/index.vue")
        },
        {
            path: "Org/Add",
            name: "OrgAdd",
            component: () => import("@/views/organization/insertandupdate.vue")
        },
        {
            path: "User",
            name: "User",
            component: () => import("@/views/user/adminuser/index.vue")
        },
        {
            path: "User/Add",
            name: "UserAdd",
            component: () => import("@/views/user/adminuser/add.vue")
        },
        {
            path: "User/Detail",
            name: "UserDetail",
            component: () => import("@/views/user/adminuser/userdetail.vue")
        },
        {
            path: "Setting",
            name: "Setting",
            component: () => import("@/views/setting/index.vue")
        },
        {
            path: "Setting/Detail",
            name: "SettingDetail",
            component: () => import("@/views/setting/add.vue")
        },
    ]
},
{
    path: "/login",
    name: "login",
    component: () =>
        import("@/views/login/login.vue")
},
{
    path: "*",
    name: "without",
    component: () =>
        import("@/views/main/home.vue")
}
];

const router = new VueRouter({
    mode: "history",
    routes
});

export default router;