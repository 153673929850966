import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import axios from "axios";
export default new Vuex.Store({
    state: {
        User: {
            Org: {
                OrganizationName: ""
            }
        },
        Date: () => {
            let date = Date.now();
            date = new Date(date);
            let start =
                date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + 1;
            let end = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            return {
                Start: start,
                End: end
            }
        },
        MenuLevel: {
            itemsAdmin: [
                { icon: "fa fa-shopping-cart", text: "รับซื่อ", to: "/Billsale" },
                {
                    icon: "fa fa-coins",
                    "icon-alt": "fa fa-coins",
                    text: "การเงิน",
                    model: false,
                    children: [
                        {
                            text: "เบิกเงินลูกค้า",
                            to: "/Finance/Widen",
                            icon: "fa fa-angle-right"
                        },
                        {
                            text: "เบิกเงินพนักงาน",
                            to: "/Finance/WidenEMP",
                            icon: "fa fa-angle-right"
                        },
                        {
                            text: "ค่าแรงพนักงาน",
                            to: "/Finance/Wage",
                            icon: "fa fa-angle-right"
                        },
                        {
                            text: "ยืม",
                            to: "/Finance/Lend",
                            icon: "fa fa-angle-right"
                        },
                        {
                            text: "คืนเงิน",
                            to: "/Finance/Receive",
                            icon: "fa fa-angle-right"
                        },
                        {
                            text: "รับเงิน",
                            to: "/Finance/Money",
                            icon: "fa fa-angle-right"
                        },
                    ]
                },
                {
                    icon: "fa fa-newspaper",
                    "icon-alt": "fa fa-newspaper",
                    text: "รายงาน",
                    model: false,
                    children: [
                        {
                            text: "รับซื่อ",
                            to: "/report/billsale",
                            icon: "fa fa-angle-right"
                        },

                        { text: "สมาชิก", to: "/report/member", icon: "fa fa-angle-right" },
                        { text: "เบิก", to: "/report/widen", icon: "fa fa-angle-right" },
                        { text: "ยืมเงิน", to: "/report/lend", icon: "fa fa-angle-right" },
                        { text: "คืนเงิน", to: "/report/recieve", icon: "fa fa-angle-right" },
                        { text: "รับเงิน", to: "/report/money", icon: "fa fa-angle-right" },
                        { text: "สรุป", to: "/report/abstract", icon: "fa fa-angle-right" }
                    ]
                },
                {
                    icon: "fa fa-cog",
                    "icon-alt": "fa fa-cog",
                    text: "ตั้งค่า",
                    model: false,
                    children: [
                        { text: "สมาชิก", to: "/Member", icon: "fa fa-angle-right" },
                        { text: "พนักงาน", to: "/Employee", icon: "fa fa-angle-right" },
                        { text: "สวนยาง", to: "/Rubber", icon: "fa fa-angle-right" },
                    ]
                }
            ],
            itemsManager: [
                { icon: "fa fa-shopping-cart", text: "รับซื่อ", to: "/Billsale" },
                {
                    icon: "fa fa-coins",
                    "icon-alt": "fa fa-coins",
                    text: "การเงิน",
                    model: false,
                    children: [
                        {
                            text: "เบิกเงินลูกค้า",
                            to: "/Finance/Widen",
                            icon: "fa fa-angle-right"
                        },
                        {
                            text: "เบิกเงินพนักงาน",
                            to: "/Finance/WidenEMP",
                            icon: "fa fa-angle-right"
                        },
                        {
                            text: "ค่าแรงพนักงาน",
                            to: "/Finance/Wage",
                            icon: "fa fa-angle-right"
                        },
                        {
                            text: "ยืม",
                            to: "/Finance/Lend",
                            icon: "fa fa-angle-right"
                        },
                        {
                            text: "คืนเงิน",
                            to: "/Finance/Receive",
                            icon: "fa fa-angle-right"
                        },
                        {
                            text: "รับเงิน",
                            to: "/Finance/Money",
                            icon: "fa fa-angle-right"
                        },
                    ]
                },
                {
                    icon: "fa fa-newspaper",
                    "icon-alt": "fa fa-newspaper",
                    text: "รายงาน",
                    model: false,
                    children: [
                        {
                            text: "รับซื่อ",
                            to: "/report/billsale",
                            icon: "fa fa-angle-right"
                        },

                        { text: "สมาชิก", to: "/report/member", icon: "fa fa-angle-right" },
                        { text: "เบิก", to: "/report/widen", icon: "fa fa-angle-right" },
                        { text: "ยืมเงิน", to: "/report/lend", icon: "fa fa-angle-right" },
                        { text: "คืนเงิน", to: "/report/recieve", icon: "fa fa-angle-right" },
                        { text: "รับเงิน", to: "/report/money", icon: "fa fa-angle-right" },
                        { text: "สรุป", to: "/report/abstract", icon: "fa fa-angle-right" }
                    ]
                },
                {
                    icon: "fa fa-cog",
                    "icon-alt": "fa fa-cog",
                    text: "ตั้งค่า",
                    model: false,
                    children: [
                        { text: "สมาชิก", to: "/Member", icon: "fa fa-angle-right" },
                        { text: "พนักงาน", to: "/Employee", icon: "fa fa-angle-right" },
                        { text: "สวนยาง", to: "/Rubber", icon: "fa fa-angle-right" },
                        { text: "สาขา", to: "/Org", icon: "fa fa-angle-right" },
                        { text: "ผู้ใช้งานระบบ", to: "/User", icon: "fa fa-angle-right" },
                        { text: "ความปลอดภัย", to: "/Setting", icon: "fa fa-angle-right" },
                    ]
                }
            ],
        }
    },
    mutations: {
        GetUser(state, callback) {
            axios.get("/Api/V1/Select/GetUser/User").then(res => {
                if (res.status == 200) {
                    state.User = res.data;
                    if (state.User.UserLevel == "M") {
                        callback(state.MenuLevel.itemsManager);
                    } else {
                        callback(state.MenuLevel.itemsAdmin);
                    }
                }
            });
        }
    },
    actions: {},
    modules: {}
});
