import swal from "sweetalert";
import Axios from "axios";

const app = (type, callback) => {
    
    Axios.get('/Api/V1/Select/GetUser/User').then(res => {
        console.log(res.data)
        if (res.status == 200 && (res.data.UserLevel == 'M')) {
            if(type == 'delete'){
                swal({
                    title: "ยืนยันการลบ?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  })
                  .then((willDelete) => {
                    if (willDelete) {
                        callback();
                    }
                  });
            }else{
                callback();
            }
        }else{
            swal({
                text: "ใส่รหัสยืนยัน",
                content: "input",
                button: {
                    text: "ตกลง",
                    closeModal: true
                }
            }).then(name => {
                if (name != null && name != "") {
                    Axios.post('/api/v1/Select/Autentication/Setting', {
                        type: type,
                        pass: name
                    }).then(res => {
                        if (res.status == 200) {
                            if(type == 'delete'){
                                swal({
                                    title: "ยืนยันการลบ?",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  })
                                  .then((willDelete) => {
                                    if (willDelete) {
                                        callback();
                                    }
                                  });
                            }else{
                                callback();
                            }
                        }
                    })
                }
            });
            
        }
    })
    
}
export default app;