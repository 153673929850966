"use strict";

import axios from "axios";
import router from "@/router/index";
import Swal from "sweetalert2"

axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");

// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_API;

axios.interceptors.request.use(
  function (request) {
    return request;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error)
    if (error.response.status == 401) {
      localStorage.removeItem("token");
      router.push("/Login");
    }
    else if (error.response.status >= 400 && error.response.status <= 499) {
      Swal.fire({
        icon: error.response.data.type,
        title: error.response.data.msg,
        text: error.response.data.text,
        showConfirmButton: false,
      })
    }
    else if (error.response.status >= 500) {
      Swal.fire({
        icon: 'error',
        title: 'ผิดพลาด',
        text: error.response.data.msg,
        showConfirmButton: false,
        allowOutsideClick: false
      })
    }
    return Promise.reject(error);
  }
);
